import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const walletList = async () => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'wallet/list',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.error('get wallet list err : ', err)

      return []
    })

  return response
}
const walletUpdate = async body => {
  //////await refreshToken()
  const response = await api
    .put({
      path: 'wallet',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update wallet err : ', err)

      return {}
    })

  return response
}
export default { walletList, walletUpdate }
