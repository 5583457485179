import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const userConfirmCheck = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'ref/key',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('check user confirm : ', err)

      return {}
    })

  return response
}
export default { userConfirmCheck }
