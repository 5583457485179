import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const userRoleList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'user/role',
      body,
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.error('get user role err : ', err)

      return []
    })

  return response
}
export default { userRoleList }
