<template>
  <div>
    <v-dialog
      v-model='isUserComfirm'
      persistent
      max-width='500'
    >
      <v-card>
        <v-card-title>
          {{ $t('confirm_password') }}
        </v-card-title>
        <v-form
          ref='formUserConfirm'
          @submit.prevent='sendUserConfirm'
        >
          <v-card-text>
            <v-autocomplete
              v-model='user'
              :items='userList'
              :label="`${$t('user')} ${$t('manager_only')}`"
              item-text='user_fullname'
              return-object
              auto-select-first
              :no-data-text="$t('no_information')"
              dense
              outlined
            ></v-autocomplete>
            <v-text-field
              v-model='userPassword'
              :rules='[required]'
              autofocus
              class='mt-1'
              dense
              :label="$t('password')"
              type='password'
              outlined
            ></v-text-field>
            <v-textarea v-model='localComment' rows='3'
                        v-if='commentStatus===true'
                        :label="$t('note')"
                        class='mt-1'
                        outlined
                        dense></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('confirm') }}
            </v-btn>
            <v-btn
              color='secondary'
              outlined
              @click="$emit('update:is-user-confirm',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiLock } from '@mdi/js'
import userRole from '@/api/userRole'
import { required } from '@/@core/utils/validation'
import userConfirm from '@/api/userConfirm'
import store from '@/store'

export default {
  model: {
    prop: 'isUserComfirm',
    event: 'update:is-user-confirm',
  },
  props: {
    isUserComfirm: { type: Boolean, default: false },
    commentStatus: { type: Boolean, default: false },
    comment: { type: String, default: '' },
  },

  setup(props, { emit }) {
    const user = ref({})
    const userList = ref([])
    const userPassword = ref('')
    const formUserConfirm = ref(null)
    const loading = ref(false)
    const localComment = ref('')

    const { userRoleList } = userRole
    const { userConfirmCheck } = userConfirm

    const getUserList = async () => {
      userRoleList({
        role_id: ['1'],
      }).then(res => {
        userList.value = res
        user.value = userList.value.find(item => item.user_id == localStorage.getItem('user_id'))
      })
    }

    const sendUserConfirm = () => {
      const isFormValid = formUserConfirm.value.validate()
      if (!isFormValid) return
      loading.value = true
      userConfirmCheck({
        user_id: user.value.user_id,
        user_key: userPassword.value,
      }).then(res => {
        userPassword.value = ''
        if (res.response) {
          emit('onSend', user.value, localComment.value)
          emit('update:is-user-confirm', false)
          loading.value = false
        } else {
          store.commit('app/ALERT', {
            message: 'passwordIsNotCollact',
            color: 'error',
          })
          loading.value = false
        }
      })
    }

    watch(() => props.isUserComfirm, () => {
      if (props.isUserComfirm) {
        getUserList()
        localComment.value = props.comment
        userPassword.value = ''
      }
    })

    return {
      loading,
      user,
      localComment,
      userList,
      userPassword,
      formUserConfirm,
      sendUserConfirm,
      required,
      icons: {
        mdiLock,
      },

    }
  },
}
</script>

<style lang='scss' scoped>

</style>
