import { onMounted, ref } from '@vue/composition-api'
import wallet from '@/api/receipt/wallet'
import { i18n } from '@/plugins/i18n'

export default function useWallet() {
  const dataTableList = ref([])
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'shop_bank_id',
      width: 50,
    },
    {
      text: i18n.t('account_alias'),
      value: 'shop_bank_name',
      width: 400,

    },
    {
      text: i18n.t('type'),
      value: 'shop_bank_default',
      align: 'left',
      width: 200,

    },
    {
      text: i18n.t('cost_amount'),
      value: 'shop_bank_wallet',
      width: 200,
      align: 'end',

    },
  ])
  const options = ref({})

  const loading = ref(false)

  const isEditMoney = ref(false)

  const dataEdit = ref({})

  const isUserConfirm = ref(false)

  const { walletList } = wallet
  onMounted(() => {
    getWalletList()
  })
  const getWalletList = () => {
    loading.value = true
    dataTableList.value = []
    walletList().then(res => {
      res.forEach(element => {
        if (element.shop_bank_wallet != '0.00') {
          dataTableList.value.push(element)
        }
      })
      options.value.itemsPerPage = -1
      options.value.page = 1
      loading.value = false
    })
  }

  return {
    isEditMoney,
    dataEdit,
    dataTableList,
    columns,
    options,
    loading,
    isUserConfirm,
    getWalletList,
  }
}
