<template>
  <div>
    <v-dialog
      v-model="isEditMoney"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('EditCostAmount') }}
        </v-card-title>
        <v-form
          ref="formEditMoney"
          @submit.prevent="updateMoney"
        >
          <v-card-text>
            <v-text-field
              v-model="dataEditLocal.shop_bank_name"
              readonly
              :label="$t('list')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="dataEditLocal.shop_bank_wallet"
              readonly
              :label="$t('cost_amount')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="dataEditLocal.shop_bank_wallet_change"
              autofocus
              :label="$t('change_amount')"
              type="number"
              outlined
              :rules="[required]"
              dense
            ></v-text-field>
            <span style="color:red">
              {{ $t("change_wallet_money") }}
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update-is-edit-money',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import wallet from '@/api/receipt/wallet'
import store from '@/store'
import { required } from '@/@core/utils/validation'
import { i18n } from '@/plugins/i18n'

export default {
  model: {
    prop: 'isEditMoney',
    event: 'update-is-edit-money',
  },
  props: {
    isEditMoney: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditMoney = ref(null)

    const loading = ref(false)

    const dataEditLocal = ref({})

    const { walletUpdate } = wallet

    const updateMoney = () => {
      const isFormValid = formEditMoney.value.validate()
      if (!isFormValid) return
      loading.value = true
      dataEditLocal.value.lang = i18n.locale
      walletUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update-is-edit-money', false)
        loading.value = false
        emit('onUpdate')
      })
    }
    watch(() => props.dataEdit, val => {
      dataEditLocal.value = JSON.parse(JSON.stringify(val))
    })

    return {
      loading,
      formEditMoney,
      dataEditLocal,
      updateMoney,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
