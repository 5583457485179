<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('wallet') }}
      </v-card-title>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.shop_bank_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.shop_bank_wallet`]="{ item }">
          <span
            class="primary--text font-weight-bold"
            style="cursor:pointer"
            @click="dataEdit = item;isUserConfirm = true"
          >
            {{ item.shop_bank_wallet | formatPrice }}
          </span>
        </template>
        <template v-slot:[`item.shop_bank_default`]="{ item }">
          <v-chip
            v-if="item.shop_bank_default == 0"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
            color="success"
            small
          >
            {{ $t("cash") }}
          </v-chip>
          <v-chip
            v-if="item.shop_bank_default == 1"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
            color="info"
            small
          >
            {{ $t("bank") }}
          </v-chip>
          <v-chip
            v-if="item.shop_bank_default == 2"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`"
            color="primary"
            small
          >
            {{ $t("credit") }}
          </v-chip>
          <v-chip
            v-if="item.shop_bank_default == 3"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
            color="error"

            small
          >
            {{ $t("online_clinic_pro") }}
          </v-chip>
          <v-chip
            v-if="item.shop_bank_default == 4"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`"
            color="warning"
            small
          >
            {{ $t("online_clinic_site") }}
          </v-chip>
          <v-chip
            v-if="item.shop_bank_default == 5"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg secondary--text`"
            color="secondary"
            small
          >
            {{ $t("pay_by_application_online") }}
          </v-chip>
          <v-chip
            v-if="item.shop_bank_default == 6"
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg secondary--text`"
            color="secondary"
            small
          >
            <v-icon
              small
              left
              class="text-white"
            >
              fa-check
            </v-icon>
            {{ $t("other_payment") }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <EditMoney
      v-model="isEditMoney"
      :data-edit="dataEdit"
      @onUpdate="getWalletList"
    />
    <UserConfirm
      v-model="isUserConfirm"
      @onSend="isEditMoney = true"
    />
  </div>
</template>

<script>
import useWallet from './useWallet'
import { formatPrice } from '@/plugins/filters'
import EditMoney from './EditMoney.vue'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'

export default {
  components: {
    EditMoney,
    UserConfirm,
  },
  filters: {
    formatPrice,
  },

  setup() {
    return {
      ...useWallet(),
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
